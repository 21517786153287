<template>
   <!-- eslint-disable  -->
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <p>Fullname: {{fullname}}</p>
        <p>Date of Birth: {{personal_info.dateofbirth}}</p>
        <p>Age: {{personal_info.age}}</p>
        <p>Civil Status: {{personal_info.age}}</p>
        <p>Sex: {{personal_info.gender}}</p>
        <p>Religion: {{personal_info.religion}}</p>
        <p>Disability: {{personal_info.disability}}</p>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <p>Ethnic Group: {{personal_info.ethnic_group}}</p>
        <p>Address: {{personal_info.address.street}},  {{personal_info.address.brgy}},  {{personal_info.address.city}}, {{personal_info.address.province}}, {{personal_info.address.region}}</p>
        <p>Contact No.: {{personal_info.contactno}}</p>
        <p>Email: {{personal_info.email}}</p>
        <p>Type of ID: {{personal_info.idtype}}</p>
        <p>SSS: {{personal_info.sss_no}}</p>
        <p>TIN: {{personal_info.tin}}</p>
      </v-col>
    </v-row>
   
  </v-container>
</template>
<script>
/* eslint-disable */

export default {

  props: {
    account:{},
  },
  data: () => ({
    valid: true,
    region:"",
    province:"",
    municipality:"",
    barrangay:"",
    philippines: [],
    regions:[],
    provinces:[],
    municipalities:[],
    barrangays:[],
    post: { post_meta: {} },
    personal_info:{
        firstname:"",
        lastname:"",
        suffix:"",
        dateofbirth:"",
        age:"",
        civil_status:"",
        gender:"",
        religion:"",
        disability:"",
        gender:"",
        religion:"",
        ethnic_group:"",
        contactno:"",
        email:"",
        idtype:"",
        sss_no:"",
        tin: "",
        address:{
          region:"",
          province:"",
          city:"",
          brgy:"",
          street:""
        },
    },
    rules: {
      required: (v) => !!v || "Required",
    },
  }),
  created() {
    this.getAddress()
    this.getPersonalInfo()
  },
  computed: {
    user() {
      if (this.$session.exists()) {
        return this.$session.get("user");
      }
      return null;
    },
    fullname() {
      return this.personal_info.lastname + ", " + this.personal_info.firstname
    }
  },
  methods: {
    initRegions() {
      var items = []
      var entries = Object.entries(this.philippines);
      entries.forEach(([key, value]) => {
        items.push({key:key, name:value.region_name});
      });
      this.regions = items
    },
    setProvinces(region_key) {
      this.personal_info.address.region = this.region.name
      var items = []
      var obj = this.philippines
      var objData= obj[region_key].province_list
      var entries = Object.entries(objData);
      entries.forEach(([key, value]) => {
        items.push(key);
      });
      console.log(items)
      this.provinces = items
    },
    setMunicipalities(provincename) {
      var items = []
      var obj = this.philippines
      var objData= obj[this.region.key].province_list
      var entries = Object.entries(objData[provincename].municipality_list);
      entries.forEach(([key, value]) => {
        items.push(key);
      });
      this.municipalities = items
    },
    setBarrangays(municipalnanme) {
      var obj = this.philippines
      var objData= obj[this.region.key].province_list
      this.barrangays = objData[this.personal_info.address.province].municipality_list[municipalnanme].barangay_list
    },
    getAddress() {
      this.$httplocal.get('philippines.json').then(response => {
          this.philippines = response.data
          this.initRegions()
      }).catch(e => {
        console.log(e)
      })
    },
    getPersonalInfo() {
      console.log("getPersonalInfo")
      this.personal_info = this.account.profile.personal
    
    },
    validate() {
      let v = this.$refs.forms.validate();
      console.log(this.personal_info)
      if (v) {
        this.SaveInfo()
      }
    },
    SaveInfo() {
      console.log(this.user.id)
        this.$http.post('account/update', {type:'personal', user_id: this.$IsNum(this.user.id), data: this.personal_info}).then(response => {  
            console.log(response.data)
            response.data.status?this.VA_ALERT("success", response.data.message):this.VA_ALERT("error", response.data.message)
           }).catch(e => {
             console.log(e)
        });
    }
  },
};
</script>