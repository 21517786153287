
export default {
    drawer: false, 
    appbar: false, 
    responsive: false,
    systemlogo: '../logo.png',
    app_icon: '../icon.png',
    applogo: '../logo2.png',
    noImage:"../img/no-image-icon.png",
    img_dir: '../img/',
    is_logged: false,
    show_alert: false,
    alert_color: "secondary",
    alert_text: "No message",
    month_daily_sales:[],
    navBar: false,
    user:'Jobseekers',
    company_types: ["Private", "Public"],
    disabilities: [
      "Blind", ""
    ]
  }
  