<template>
  <v-dialog v-model="dailog" persistent fullscreen>
    <v-card>
      <v-card-title class="text-h6" dense>

            <v-icon left color="primary">
              mdi-account-multiple
            </v-icon> Applicants for {{job.title}}

            <v-spacer />
              <v-icon  @click="emitToParent('close')">
              mdi-close-box
            </v-icon>
    </v-card-title>
      <v-card-text>
        <div v-if="applicants.length == 0" class="text-center mt-4"><em class="text-warning ">No applicants yet!</em></div>
        <v-row v-else>
          <v-col cols="4">
          
            <v-list two-line>
                <template v-for="(item, index) in applicants">
                  <template v-if="item.applicant!=null">
                  <v-list-item
                    :key="index"
                  >
                    <!-- <v-list-item-avatar>
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar> -->

                    <v-list-item-content>
                      <v-list-item-title><a :href="'/public/' + item.user_id" target="_blank">{{ item.applicant!=null?item.applicant.lastname:"" }},  {{ item.applicant!=null?item.applicant.firstname:"" }}</a></v-list-item-title>
                      <v-list-item-subtitle><span><v-icon small left>mdi-cellphone-basic</v-icon>{{ item.applicant.contactno?item.applicant.contactno:"N/A" }}</span></v-list-item-subtitle>
                      <v-list-item-subtitle><span><v-icon small left>mdi-email</v-icon>{{ item.applicant.email?item.applicant.email:"N/A" }}</span></v-list-item-subtitle>
                      <v-list-item-subtitle>Applied: <em class="text-warning">{{ $moment(item.applied_dt).fromNow()}}</em></v-list-item-subtitle>
                     
                    </v-list-item-content>
                    <v-list-item-action>
                        <!-- <v-checkbox
                          :id="index+'-c'"
                          v-model="recipients"
                          :value="{name:item.applicant.lastname + ',' + item.applicant.firstname, id: item.user_id }"
                        /> -->
                        <v-btn x-small  color="success" @click="addAttendee(item)"><v-icon small>mdi-account-plus</v-icon></v-btn>
                        </v-list-item-action>
                  </v-list-item>
                  <v-divider  :key="index + 'div'"></v-divider>
                  </template>
                </template>
              </v-list>
          </v-col>
          <v-col cols="8">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
            <div class="pa-4">
              <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Select Date"
                                 :value="schedule.sched_dt"
                                 readonly
                                 filled
                                dense
                                :rules="nameRules"
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="schedule.sched_dt" no-title scrollable @input="date_menu=false" />
                    </v-menu>

              <v-text-field 
                v-model="schedule.subject"
                label="Schedule Purpose"
                outlined
                :rules="nameRules"
                dense
              />
              <div class="mb-2">
                Attendees:
                <template  v-for="(item, index) in attendees">
                     <v-chip
                          :key="index"
                          close
                          color="primary"
                          @click:close="removeAttendees(index)"
                          class="ma-1"
                          label
                          outlined
                        >
                        {{ item.name}}
                        </v-chip>
                </template>
                <v-divider/>
              </div>
               <v-tiptap v-model="schedule.message" />
              </div>
              <div>
                  <v-toolbar dense flat>
                      <v-spacer></v-spacer>
                      <em v-if="alert" class="mr-2 text-success"><v-icon color="success">mdi-check</v-icon>Schedule Created</em>
                      <v-btn :loading="loading" @click="validate()" color="success" small>Create Schedule</v-btn>
                  </v-toolbar>
            
               </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    date_menu: false,
    loading: false,
    valid: true,
    alert: false,
    schedule: {subject:"", message:""},
    applicants:[],
    attendees:[],
    dailog: false,
    company: {},
    post: { post_meta: { salary: "" } },
    state: 'new',
    user:{},
    nameRules: [
      v => !!v || 'Field is required'
    ],
  }),
  watch: {
    show(v) {
      v ? this.dailog = true : this.dailog = false
      if(v) {
        if (this.$session.get("user")) {
         this.user = this.$session.get("user");
        } else {
          this.user = null
        }
        this.applicants = []
          this.getJobApplications()
      } else {
          this.applicants = []
          this.attendees = []
      }
    }
  },
  created() {
  },
  methods: {
    emitToParent(action) {
      this.$emit('DialogEvent', { action: action })
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.sendschedule()  
      }
    },
   
    removeAttendees(index){
      if(this.attendees.length>0) {
        this.attendees.splice(index,1)
      }
    },
    addAttendee(item){
      var params = {
        name: item.applicant.lastname +', '+ item.applicant.firstname,
        applicant_id: item.user_id
      }
      this.attendees.push(params)
      console.log(this.attendees)
    },
    sendschedule(){
    
      this.loading = true
      this.schedule.attendees = this.attendees
      console.log(this.schedule)
      this.$http.post('schedule/create',this.schedule).then(response => {  
             if(response.data.status) {
              this.alert = true
              setTimeout(()=>{
                this.clearschedule()
                this.alert = false
                this.emitToParent('close')
              },1500)
             }
              this.loading = false
            }).catch(e => {
              this.loading = false
            });
    },
    clearschedule(){
      this.recipients = []
      this.schedule =  {subject:"", message:""}
    },
   getJobApplications(){
      this.recipients = []
      this.$http.post('get_job_applicants', {id:this.job.id}).then(response => {  
              response.data.status?this.applicants = response.data.applicants:this.applicants=[]
              console.log(this.applicants )
            }).catch(e => {
              console.log(e)
            }).catch((error) => {
              console.error(error);
          });
   }
  },
}
</script>
  